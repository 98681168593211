<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="充值中心"
                z-index="99"
                class="header"
                @click-left="leftClick">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <div class="topup-bg">
                <p class="topup-top-left">
                    <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon">
                    书豆余额
                </p>
                <p class="topup-top-right" v-if="base_infoList.user_data">{{base_infoList.user_data.coin}}</p>
            </div>
            <div class="topup-list" v-if="base_infoList.normal">
                <template v-for="item in base_infoList.normal">
                    <div class="topup-div" :class="item.status?'active':''" @click="topupClick(item)">
                        <p class="topup-top" v-if="item.smoney">赠送{{item.smoney}}书豆</p>
                        <p class="topup-title"><span>￥</span>{{item.money}}</p>
                        <p class="topup-text">{{item.coin}}书豆</p>
                    </div>
                </template>
            </div>
            <div class="bottom-text-list">
                <p>温馨提示</p>
                <p>如果充值失败，请尽快联系我们的客服。您可以从“我的”页面，点击“在线客服”进行反馈，谢谢亲`</p>
            </div>
        </div>
        <!--        支付方式-->
        <van-popup v-model="show" position="bottom" @close="showClick">
            <div class="top-list">
                <div @click="weixin">
                    <p>
                        <img src="../../assets/images/upweixin.png" alt="" class="upweixin">
                    </p>
                    <p class="top-text">微信支付</p>
                </div>
                <div @click="zfb" v-if="!userAgent">
                    <p>
                        <img src="../../assets/images/upzfb.png" alt="" class="upweixin">
                    </p>
                    <p class="top-text">支付宝支付</p>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "Topup",
        data() {
            return {
                show: false,
                base_infoList: [],
                Time: {},
                userAgent: true,
                fromPath: '',
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.fromPath = from.path
            })
        },
        created() {
            this.base_info()
            if (/MicroMessenger/.test(window.navigator.userAgent)) {
                //在微信中
                this.userAgent = true
            } else {
                //在浏览器中打开
                this.userAgent = false
            }
        },
        methods: {
            leftClick() {
                if (this.fromPath == '/wallet') {
                    this.$router.replace({
                        path: '/wallet'
                    })
                } else {
                    this.$router.go(-1)
                }
            },
            base_info() {
                this.$axios.get('/api/charge/base_info')
                    .then(res => {
                        var res = res.data.data
                        res.normal.forEach(item => {
                            item.status = false
                        })
                        this.base_infoList = res
                        this.base_infoList.user_data.coin = this.base_infoList.user_data.coin.toLocaleString()
                    })
            },
            topupClick(item) {
                item.status = true
                this.Time = item
                this.show = true
            },
            showClick() {
                this.Time.status = false
            },

            weixin() {
                this.$toast('微信支付')
                if (this.userAgent) {
                    //微信内置支付
                    this.$axios.post('/api/charge/create_order', {
                        charge_id: this.Time.id,
                        pay_type: '1',
                        scene: 'h5',
                        scene_type: 'wechat',
                    })
                        .then(res => {
                            this.show = false
                            this.Time.status = false
                            this.onBridgeReady(res.data.data)
                        })
                } else {
                    //浏览器 微信支付
                    this.$axios.post('/api/charge/create_order', {
                        charge_id: this.Time.id,
                        pay_type: '1',
                        scene: 'h5',
                    })
                        .then(res => {
                            window.location.href = res.data.data.mweb_url
                        })
                }

            },
            //公众号支付 微信支付
            onBridgeReady(res) {
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.appId,     //公众号名称，由商户传入
                        "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                        "nonceStr": res.nonceStr, //随机串
                        "package": res.package,
                        "signType": res.signType,         //微信签名方式：
                        "paySign": res.paySign, //微信签名
                    }, res => {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            this.$toast.success('充值成功')
                            this.$router.go(-1)
                        }
                    }
                );
            },
            //公众号支付 微信支付 end
            // 支付宝支付
            zfb() {
                this.$toast('支付宝支付')
                if (this.userAgent) {
                    //微信内置浏览器 支付宝支付
                } else {
                    //浏览器 支付宝支付
                    this.$axios.post('/api/charge/create_order', {
                        charge_id: this.Time.id,
                        pay_type: '2',
                        scene: 'h5',
                    })
                        .then(res => {
                            const div = document.createElement('div');
                            div.innerHTML = res.data.message
                            document.body.appendChild(div)
                            document.forms[0].submit()
                        })
                }
            },
        }
    }
</script>

<style scoped>
    .top-text {
        color: #BFC2CC;
        font-size: 20px;
        text-align: center;
    }

    .top-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 60px 40px;
    }

    .upweixin {
        width: 107px;
        height: 127px;
    }

    .bottom-text-list {
        color: #BFC2CC;
        font-size: 24px;
    }

    .bottom-text-list > p {
        margin-bottom: 18px;
        line-height: 30px;
    }

    .topup-top {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        background: #4D77FD;
        color: #fff;
        padding: 10px 15px;
        border-radius: 0 8px 0 10px;
    }

    .active {
        border: 2px solid #4D77FD !important;
        background: #fff !important;
    }

    .active:after {
        content: '';
        background: url("../../assets/images/active.png") no-repeat;
        background-size: 42px;
        width: 42px;
        height: 42px;
        display: inline-block;
        position: absolute;
        right: -1px;
        bottom: -1px;
    }

    .active .topup-title, .active .topup-text {
        color: #4D77FD !important;
    }

    .topup-text {
        color: #909399;
        font-size: 24px;
    }

    .topup-title {
        color: #333333;
        font-size: 48px;
        margin-bottom: 18px;
        margin-top: 13px;
    }

    .topup-title > span {
        font-size: 30px;
    }

    .topup-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .topup-div {
        width: 320px;
        height: 160px;
        padding: 20px;
        border: 2px solid #fff;
        background: rgba(242, 245, 248, 1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 30px;
        position: relative;
    }

    .topup-top-left {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 28px;
    }

    .topup-top-right {
        color: #fff;
        font-size: 42px;
    }

    .bookbean_icon {
        width: 26px;
        height: 27px;
        margin-right: 10px;
    }

    .topup-bg {
        background: url("../../assets/images/topup.png") no-repeat;
        background-size: cover;
        height: 221px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
    }
</style>